import { Map } from "immutable";

export function clearToken() {
    //localStorage.removeItem('id_token');
    //localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("expireTime");
    localStorage.removeItem("currentUser");
}

export function getToken() {
    try {
        const token = localStorage.getItem("token");
        const expireTime = localStorage.getItem("expireTime");
        const currentUser = localStorage.getItem("currentUser");

        return new Map({
            token,
            expireTime,
            currentUser,
        });
    } catch (err) {
        clearToken();
        return new Map();
    }
}

export function timeDifference(givenTime) {
    givenTime = new Date(givenTime);
    const milliseconds = new Date().getTime() - givenTime.getTime();
    const numberEnding = (number) => {
        return number > 1 ? "s" : "";
    };
    const number = (num) => (num > 9 ? "" + num : "0" + num);
    const getTime = () => {
        let temp = Math.floor(milliseconds / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            const month = number(givenTime.getUTCMonth() + 1);
            const day = number(givenTime.getUTCDate());
            const year = givenTime.getUTCFullYear() % 100;
            return `${day}-${month}-${year}`;
        }
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            if (days < 28) {
                return days + " day" + numberEnding(days);
            } else {
                const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                const month = months[givenTime.getUTCMonth()];
                const day = number(givenTime.getUTCDate());
                return `${day} ${month}`;
            }
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return `${hours} hour${numberEnding(hours)} ago`;
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return `${minutes} minute${numberEnding(minutes)} ago`;
        }
        return "a few seconds ago";
    };
    return getTime();
}

export function randomFromRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randomByPercentage(num, percent) {
    const max = (num * (100 + percent)) / 100;
    const min = (num * (100 - percent)) / 100;
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function randomIncreaseByPercentage(num, percent) {
    const max = (num * (100 + percent)) / 100;
    return Math.floor(Math.random() * max);
}

export function randomFillArray(initNum, count) {
    let arr = [];
    var temp = initNum;
    for (let i = 1; i <= count; i++) {
        const next = randomFromRange(1, temp);
        const v = temp - next;
        if (i === count) {
            arr.push(temp);
        } else {
            arr.push(v);
            temp = next;
        }
    }
    return arr;
}
